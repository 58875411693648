export default {
	name: "Detail",
	components: {},
	props: {
		detailInfo_origin: Object,
		detailInfo_fft: Object,
	},
	data() {
		return {
			showReason: false,
			showImgBig: false,
			reason: "",
		};
	},
	methods: {}
};
