import Cookies from "js-cookie";
import axios from "axios";
import { SignAll, DownAllMessageList, SignById } from "@/services/config";
export default {
	props: ["getMagessId", "getIssShow"],
	data: () => {
		return {
			showMagess: true,
			activeName1: "frist",
			activeName: 1,
			magessList: [],
			total: 0,
		};
	},
	created() {
		if (this.getIssShow) {
			this.activeName = this.getIssShow + 1;
		}
		this.getMessageList(0, this.getMagessId);
	},
	filters: {
		setMessageTitle: function (value) {
			if (value == 1) {
				value = " 机构会员申请通知";
			} else if (value == 2) {
				value = "机构会员申请通知";
			} else if (value == 3) {
				value = "带教老师申请通知";
			}
			return value;
		}
	},
	methods: {
		// 未读已读消息切换
		handleClick(tab) {
			this.activeName = 1;
			this.getMessageList(tab.index, "");
		},
		getMessageList(isRead, id) {
			this.tokenVal = Cookies.get("token2");
			this.axios
				.post(
					DownAllMessageList,
					{
						id: id,
						isRead: isRead,
						page: 1
					},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						this.magessList = res.data.data.records;
						this.magessList[0].read = true;
						this.$nextTick(function () {
							this.setMessageRead(1);
						});
					}
				});
		},
		// 变为已读
		setMessageRead(val) {
			let index = this.magessList[val - 1].id;
			this.magessList[val - 1].read = true;
			this.tokenVal = Cookies.get("token2");
			this.axios
				.post(
					SignById,
					{
						id: index
					},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						this.total = res.data.data;
						this.$emit("setTotal", this.total);
						this.showSign = val - 1;
					}
				});
		},
		// 全部变为已读
		setAllRead() {
			this.tokenVal = Cookies.get("token2");
			this.axios
				.post(
					SignAll,
					{},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						this.getMessageList(0, this.getMagessId);
						this.total = 0;
						this.$emit("setTotal", this.total);
					}
				});
		}
	},
	components: {
		axios
	}
};
