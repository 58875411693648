import Cookies from "js-cookie";
import axios from "axios";
import PubSub from "@/lib/Pub";
import { UploadHeadUrl, UpdateAdminInfo } from "@/services/config";
export default {
	name: "SetUser",
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				if (this.ruleForm2.checkPass !== "") {
					this.$refs.ruleForm2.validateField("checkPass");
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.ruleForm2.pass) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			changeHaed: false,
			avatar: this.$root.adminInfo.headImg,
			ruleForm2: {
				pass: "",
				checkPass: "",
				age: ""
			},
			rules2: {
				pass: [{ validator: validatePass, trigger: "blur" }],
				checkPass: [{ validator: validatePass2, trigger: "blur" }]
			},
			tokenObj: {
				token: ""
			},
			imageUrl: "",
			UploadHeadUrl
		};
	},
	created() {
		this.tokenObj.token = Cookies.get("token2");
	},
	methods: {
		handleRemove() {
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					// alert(this.ruleForm2.pass);
					this.upDataPass(this.ruleForm2.pass);
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw);
			if (file.response.code == 200) {
				this.avatar = file.response.data.name;
				this.$emit("GetheadimgUrl", this.avatar);
				this.$message("头像修改成功");
			}
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === "image/jpeg";
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error("上传头像图片只能是 JPG 格式!");
			}
			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 2MB!");
			}
			return isJPG && isLt2M;
		},
		upDataPass(val) {
			this.tokenVal = Cookies.get("token2");
			this.axios
				.post(
					UpdateAdminInfo,
					{
						password: val
					},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						this.$message({
							message: "密码修改成功！",
							type: "success"
						});

						// 返回登录页面
						setTimeout(() => {
							Cookies.set("token2", "");
							PubSub.publish("loginOutEvent2");
						}, 2000);
					}
				});
		}
	},
	components: {
		axios,
		PubSub
	}
};
