export default {
	name: "reviewHistory",
	components: {},
	props: {
		reviewHistory: Array
	},
	data() {
		return {};
	},
	filters: {
		setStateText: function (value) {
			if (value == 0) {
				value = "未审核";
			} else if (value == 1) {
				value = "已通过";
			} else {
				value = "未通过";
			}
			return value;
		}
	},
	methods: {}
};
