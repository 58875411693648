
import ReviewRight from "@/components/review/ReviewRight.vue";
import Message from "@/components/review/Message.vue";
import SetUser from "@/components/review/SetUser.vue";
import fftDetailInfo from "@/views/review/util";
import { ReviewService } from "@/services";
import PubSub from "@/lib/Pub";
import config from "./config";

export default {
	name: "review",
	data: () => {
		return {
			tableData: [],
			detailInfo_origin: {},
			detailInfo_fft: {},
			showDetail: false,
			showLoading: false,
			showHistory: false,
			showMagess: true,
			showSetUser: false,
			MagessId: "",
			currentPage: 0,
			pageSize: 0,
			total: 0,
			state: "",
			type: "",
			isShow: "",
			headImgUrl: "",
			headerTotal: 0,
			reviewHistory: [],
			company: false,
			login: false,
			states: [
				{
					value: "",
					label: "全部"
				},
				{
					value: 0,
					label: "未审核"
				},
				{
					value: 1,
					label: "已通过"
				},
				{
					value: 2,
					label: "未通过"
				}
			],
			types: [
				{
					value: "",
					label: "全部"
				},
				{
					value: 1,
					label: "SMO公司"
				},
				{
					value: 2,
					label: "CRO公司"
				},
				{
					value: 3,
					label: "制药企业"
				},
				{
					value: 4,
					label: "其他"
				}
			]
		};
	},
	computed: {
		headerData() {
			let type = this.$route.params.type;
			if (type * 1 == type) return config[type - 1];
		}
	},
	created() {
		if (this.$route.params.type == 1 || this.$route.params.type == 3) {
			PubSub.subscribe("LoginSuccessEvent", () => this.getPageList());
			this.getPageList();
		} else if (this.$route.params.type == 2) {
			this.company = true;
			PubSub.subscribe("LoginSuccessEvent", () => this.getPageListSmo());
			this.getPageListSmo();
		}
	},
	methods: {
		onToggleDetail() {
			this.showDetail = !this.showDetail;
		},
		onShowHistory() {
			this.showHistory = !this.showHistory;
		},
		lisLogFn(data) {
			this.login = data;
		},
		async getPageList() {
			this.showLoading = true;
			this.showDetail = false;
			let userType = this.$route.params.type;
			let data = await ReviewService.getPageList(
				this.state,
				this.currentPage,
				userType
			);
			this.showLoading = false;
			if (data.code === 401) {
				// PubSub.publish("showLoginEvent");
				return;
			}
			this.tableData = data.data.records;
			this.total = data.data.total;
			this.pageSize = data.data.size;
		},

		async getPageListSmo() {
			this.showLoading = true;
			this.showHistory = false;
			// let userType = this.$route.params.type;
			let data = await ReviewService.getPageListSmo(
				this.state,
				this.currentPage,
				this.type
			);
			this.showLoading = false;
			if (data.code === 401) {
				// PubSub.publish("showLoginEvent");
				return;
			}
			this.tableData = data.data.records;
			this.total = data.data.total;
			this.pageSize = data.data.size;
		},

		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.$route.params.type == 1 || this.$route.params.type == 3) {
				this.getPageList();
			} else if (this.$route.params.type == 2) {
				this.getPageListSmo();
			}
		},
		async getDetailInfo(id) {
			this.showLoading = true;
			let type = this.$route.params.type;
			let res = await ReviewService.getDetailInfo(id, type);
			this.showLoading = false;
			if (res.code == 200) {
				this.detailInfo_origin = res.data;
				this.detailInfo_fft = fftDetailInfo(res.data, type);
				this.onToggleDetail();
			} else if (res.code === 401) {
				PubSub.publish("showLoginEvent");
				return;
			} else {
				this.$message("服务器异常，请稍后重试！");
			}
		},
		async getHistoryInfo(id) {
			this.showLoading = true;
			let type = this.$route.params.type;
			let res = await ReviewService.getHistoryInfo(id, type);
			this.showLoading = false;
			if (res.code == 200) {
				this.reviewHistory = res.data;
				this.onShowHistory();
			} else if (res.code === 401) {
				PubSub.publish("showLoginEvent");
				return;
			} else {
				this.$message("服务器异常，请稍后重试！");
			}
		},
		async submitReview(state, reason) {
			let type = this.$route.params.type;
			let res;
			this.showLoading = true;
			let id = this.detailInfo_origin.id;
			res = await ReviewService.submitReview(id, state, reason, type);
			this.showLoading = false;
			if (res.code == 200) {
				this.$message("审核完成");
				this.tableData.forEach(function (item) {
					if (item.id === id) {
						item.state = state;
					}
				});
				this.onToggleDetail();
			} else {
				this.$message("服务器异常，请稍后重试！");
			}
		},
		onToggleMagess(value) {
			this.showMagess = value;
		},
		onToggleSetUserM(val) {
			this.showSetUser = val
		},
		onToggleMagessU(val) {
			this.showMagess = val;
		},
		// 头像传参
		onToggleSetUser(val) {
			this.showSetUser = val;
		},
		getMagessId(id) {
			this.MagessId = id;
		},
		getMagessShow(id) {
			this.isShow = id;
		},
		setTotal(val) {
			this.headerTotal = val;
		},
		GetheadimgUrl(url) {
			this.headImgUrl = url;
		}
	},
	watch: {
		"$route.params.type": function () {
			if (this.$route.params.type == 2) {
				this.company = true;
				this.getPageListSmo();
			} else if (this.$route.params.type == 1 || this.$route.params.type == 3) {
				this.company = false;
				this.getPageList();
			}
			this.currentPage = 0;
		},
		state: function () {
			if (this.$route.params.type == 2) {
				this.getPageListSmo();
			} else if (this.$route.params.type == 1 || this.$route.params.type == 3) {
				this.getPageList();
			}
		},
		type: function () {
			this.getPageListSmo();
		}
	},
	components: {
		ReviewRight,
		Message,
		SetUser
	}
};
