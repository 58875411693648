function getState(type) {
    if (type === 0) {
        return "未审核"
    } else if (type === 1) {
        return "已通过"
    } else {
        return "未通过"
    }
}
function getType(type) {
    if (type === 1) {
        return "SMO公司"
    } else if (type === 2) {
        return "CRO公司"
    } else if (type === 3) {
        return "制药企业"
    } else {
        return "其他"
    }
}
let header1 = [
    {
        label: '机构名称',
        value: 'name',
    },
    {
        label: '联系人',
        value: 'linkName'
    },
    {
        label: '联系人手机号',
        value: 'linkPhone'
    },
    {
        label: '状态',
        value: 'state',
        filter: getState
    }
];

let header2 = [{
    label: '企业名称',
    value: 'name',
},
{
    label: '企业类别',
    value: 'type',
    filter: getType
},
{
    label: '联系人',
    value: 'linkName'
},
{
    label: '联系人手机号',
    value: 'linkPhone'
},
{
    label: '状态',
    value: 'state',
    filter: getState
}]

let header3 = [{
    label: '姓名',
    value: 'name',
},
{
    label: '职务',
    value: 'position'
},
{
    label: '所在机构',
    value: 'institutionId'
},
{
    label: '从事研究年限',
    value: 'years'
},
{
    label: '联系电话',
    value: 'linkPhone'
},
{
    label: '状态',
    value: 'state',
    filter: getState
}]

let header4 = [{
    label: '编号',
    value: 'number',
},
{
    label: '名称',
    value: 'videoName'
},
{
    label: '分类',
    value: 'classify'
},
{
    label: '版本',
    value: 'version'
}]

export default [header1,
    header2,
    header3,
    header4
]