export default {
	name: "ReviewRight",
	components: {},
	props: {
		tableData: Array,
		header: Array
	},
	data() {
		return {};
	},
	created() { },
	filters: {
		setExamineText: function (value) {
			return value ? "查看" : "审核";
		}
	},
	methods: {
	}
};
