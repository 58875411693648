import Table from "@/components/review/Table.vue";
import Detail from "@/components/review/Detail.vue";
import ReviewHistory from "@/components/review/ReviewHistory.vue";
export default {
	name: "ReviewRight",
	components: {
		Detail,
		Table,
		ReviewHistory,
	},
	props: {
		tableData: Array,
		detailInfo_origin: Object,
		detailInfo_fft: Object,
		reviewHistory: Array,
		header: Array,
		messageList: Array,
		showDetail: Boolean,
		showMagess: Boolean,
		showHistory: Boolean,
	},
	data() {
		return {
			tableData3: [],
		};
	},
	created() {

	},
	methods: {
		getDetailInfo(id) {
			this.$emit("getDetailInfo", id);
		},
		getHistoryInfo(id) {
			this.$emit("getHistoryInfo", id);
		},
		submitReview(state, remark) {
			this.$emit("submitReview", state, remark);
		},
	}
};
