let fftMechanismInfo = {
  name: {key: '机构全称'},
  address: {key: '通讯地址',},
  establishTime: {key: '临床试验机构成立时间', filter: (value)=>{return fftDate(value)}},
  clinicalCount: {key: '每年临床研究数量',},
  crcSoure: {key: 'CRC来源', filter: (value)=>{return fftCrcSoure(value)}},
  crcCount: {key: 'CRC人数', },
  acceptCrcCount: {key: '可以承接CRC进修人数', },
  advancedProject: {key: '可提供进修的项目', filter: (value)=>{ return fftAdvancedProject(value)}},
  licenseImg: {key: '临床试验机构申请表',},
};

let fftCompanyInfo ={
  name: {key: '企业名称', },
  type: {key: '企业类别', filter: (value)=>{return fftType(value)}},
  natrue: {key: '企业性质', filter: (value)=>{return fftNatrue(value)}},
  address: {key: '计划培训crc人员分布'},
  regTime: {key: '企业注册时间'},
  clinicalCount: {key: '每年临床研究数量',},
  mechanismCode: {key: '组织机构代码',},
  companyCrcCount: {key:'企业CRC人数'},
  crcCount: {key: '计划培训CRC人数',},
  trainDirection: {key: '培训方向', filter: (value)=>{return fftTrainDirection(value)}},
  licenseImg: {key: '企业成员申请申请表',},
};

let fftTeatherInfo = {
  name: {key: '申请人',},
  institutionId: {key: '所在机构',},
  position: {key: '现任职务',},
  title: {key: '职称',},
  linkPhone: {key: '联系电话',},
  email: {key: '邮箱',},
  linkAddress: {key: '联系地址',},
  years: {key: '从事临床研究管理年限',},
  graduateMajor: {key: '毕业专业',},
  isteach: {key: '是否可安排足够时间带教', filter: (value)=>{return fftIsTeach(value)}},
  teachCount: {key: '可带学员数',},
  licenseImg: {key: '带教老师申请表',},
}

let fftLinkInfo = {
  linkName: '联系人姓名',
  linkPhone: '联系人手机号',
  linkEmail: '联系人邮箱',
  linkAddress: '通讯地址',
  position: '现任职务',
  linkZcode: '邮编',
  linkFax: '传真'
};

function fftNatrue(value) {
  let list =[
    '国有企业',
    '集体企业',
    "民营企业", 
    '社会团体',
    '股份制企业',
    '合资企业',
    '外资企业',
  ];
  return list[value-1]
}

function fftTrainDirection(value){
  let list = [
    'I期',
    'II期',
    'III期',
    'IV期',
    'BE期',
  ];
  value = value.split(",").reduce((result, item) => {
      return result + (result ? ", " : "") + list[item-1];
    }, "")
  return value
}


function fftAdvancedProject(value) {
  let list = [
    'I期',
    'II期',
    'III期',
    'IV期',
    'BE期',
  ];
  value = value.split(",").reduce((result, item) => {
      return result + (result ? ", " : "") + list[item-1];
    }, "")
  return value
}

function fftIsTeach(value){
  let list = [
    '是',
    '否',
  ];
  return list[value-1]
}

function fftCrcSoure(value) {
  let  list = [
    '申办方提供',
    '研究者聘用',
    'SMO公司提供',
    '自聘',
  ];
  value = value.split(",").reduce((result, item) => {
    return result + (result ? ", " : "") + list[item-1];
  }, "")
  return value
  //return list[value-1]
}

function fftType(value){
  let list = [
    'SMO公司',
    'CRO公司',
    '制药企业',
    '其他'
  ];
  return list[value-1]
}

let fftDate = function(value){
  // let date = new Date(value);
  // let year = date.getFullYear();
  // let month = date.getMonth() + 1;
  // let day = date.getDate();
  // value = [
  //   year,
  //   month > 9 ? month : "0" + month,
  //   day > 9 ? day : "0+9"
  // ].join("-");
  return value
}

function fftDetailInfo(info, type) {
  let detailInfo = {};
  let fft, title;
  if (type == 3) {
    fft = fftTeatherInfo;
    title = "带教信息";
    detailInfo[title] = {};
  } else if (type == 2) {
    fft = fftCompanyInfo;
    title = "企业信息";
    detailInfo[title] = {};
    detailInfo["联系人信息"] = {};
  } else if (type == 1) {
    fft = fftMechanismInfo;
    title = "机构信息";
    detailInfo[title] = {};
    detailInfo["联系人信息"] = {};
  }

  for (let key in info) {
    if (key in fftLinkInfo && type != 3) {
      detailInfo["联系人信息"][fftLinkInfo[key]] = info[key];
    } else if (key in fft) {
      let filter = fft[key].filter;
      detailInfo[title][fft[key].key] = filter ? filter(info[key]) : info[key];
    }
  }
  return detailInfo;
}

export default fftDetailInfo